"use client";
import styled from "styled-components";
import Link from "next/link";
import Image from "next/image";
import gsap from 'gsap';
import { useEffect } from "react";
import ScrollTrigger from 'gsap/ScrollTrigger';
import Img from "@/components/Img";
gsap.registerPlugin(ScrollTrigger);

const NewsBox = ({ img, title, date, link }) => {
    return (
        <StyledComponent className={'newsbox'}>
            <div className={'newsbox__single'}>
                <Link href={`/news-events/${link ? link : ''}`} prefetch={true}>
                    <div className={'newsbox__single__top reveal-img'}>
                        <Img src={img}/>
                        {/*<Image src={img ? img : ''} alt={'news'}*/}
                        {/*       blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQE..." placeholder={"blur"}*/}
                        {/*       objectFit={'cover'} layout={'fill'}*/}
                        {/*/>*/}
                    </div>
                    <div className={'newsbox__single__bottom'}>
                        <p className={'date'}>{date ? date : ''}</p>
                        <p className={'short-desc split-up'}>{title ? title : ''}</p>
                    </div>
                </Link>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
    .newsbox {
        &__single {
            &__top {
                position: relative;
                padding-top: calc(250 / 370 * 100%);
                margin-bottom: 20px;
                border-radius: 0 50px 0 50px !important;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    transform: scale(1.01);
                    transition: transform .7s ease-in-out;
                }
                
                &:hover{
                    img{
                        transform: scale(1.10);
                        transition: transform .7s ease-in-out;
                    }
                }
            }
            &__bottom {
                .date {
                    color: #137EC3;
                    margin-bottom: 10px;
                }
                .short-desc {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 400;
                    color: #000;
                }
            }
        }

        @media (max-width: 767px) {
            &__single {
                &__top {
                    padding-top: calc(240 / 345 * 100%);
                }
            }
        }
    }
`;

export default NewsBox;
