"use client";

import styled from "styled-components";
import Image from "next/image";
import { useEffect, useState } from "react";

const Img = ({
                 src,
                 position,
                 objectFit,
                 height,
                 width,
                 alt,
                 left,
                 margin,
                 right,
                 top,
                 bottom,
                 transition,
             }) => {
    const [deviceWidth, setDeviceWidth] = useState(0);
    const [imageError, setImageError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        function HandleWidth() {
            setDeviceWidth(window.innerWidth);
        }

        window.addEventListener("resize", HandleWidth);
        HandleWidth();
        return () => {
            window.removeEventListener("resize", HandleWidth);
        };
    }, []);

    // Much shorter blur data URL that works better across browsers
    const shimmer = `data:image/svg+xml;base64,${Buffer.from(`
    <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient id="g">
          <stop stop-color="#f3f3f3" offset="20%" />
          <stop stop-color="#ecebeb" offset="50%" />
          <stop stop-color="#f3f3f3" offset="70%" />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="#f3f3f3" />
    </svg>
  `).toString('base64')}`;

    return (
        <StyledImg
            className="global-image"
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
            transition={transition}
            imageError={imageError}
            isLoading={isLoading}
        >
            {!imageError ? (
                <Image
                    blurDataURL={shimmer}
                    placeholder="blur"
                    alt={alt || "Image"}
                    fill
                    src={src || ""}
                    priority={true}
                    objectFit={objectFit || "cover"}
                    onError={() => setImageError(true)}
                    onLoadingComplete={() => setIsLoading(false)}
                />
            ) : ''
            }
            {isLoading && !imageError && (
                <div className="loading-placeholder" />
            )}
        </StyledImg>
    );
};

const StyledImg = styled.div`
    position: ${(props) => props.position || "absolute"};
    height: ${(props) => props.height || "100%"};
    width: ${(props) => props.width || "100%"};
    top: ${(props) => props.top || 0};
    left: ${(props) => props.left || 0};
    bottom: ${(props) => props.bottom || 0};
    right: ${(props) => props.right || 0};
    margin: ${(props) => props.margin || 0};
    overflow: hidden;
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        ${(props) => props.transition && `transition: 1.4s ease`};
    }
    

    .loading-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
                90deg,
                #f3f3f3 0%,
                #ecebeb 50%,
                #f3f3f3 100%
        );
        background-size: 200% 100%;
        animation: shimmer 1.5s infinite;
        display: ${(props) => (props.isLoading ? "block" : "none")};
    }

    @keyframes shimmer {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }

    /* Safari-specific styles */
    @supports (-webkit-touch-callout: none) {
        .loading-placeholder {
            opacity: ${(props) => (props.isLoading ? 1 : 0)};
            transition: opacity 0.3s ease;
        }
    }
`;

export default Img;