'use client'
import StyledComponentsRegistry from "@/lib/registry";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import GlobalStyle from "@/styles/globalStyle";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import Footer from "@/components/Footer";
import { Parallax } from "@/components/animations/Parallax";
import { TextAnimation } from "@/components/animations/TextAnimation";
import { ImageReveal } from "@/components/animations/ImageReveal";
import Menu from "@/components/Menu";
import {usePathname, useRouter} from "next/navigation";
import PageTransition from "@/components/PageTransition";
import { useGSAP } from "@gsap/react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {ScrollSmoother} from "gsap/dist/ScrollSmoother";
import {ScrollToPlugin} from "gsap/dist/ScrollToPlugin";
import Transition from "@/components/Transition";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import "/app/global.css";

export default function RootLayout({ children }) {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
    const location = usePathname();

    const el = useRef();
    // let smoother;
    //
    // useLayoutEffect(() => {
    //     ScrollTrigger.refresh();
    //     if (window.innerWidth > 768 && !location.startsWith('/news-events/')) {
    //         smoother = ScrollSmoother.create({
    //             smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
    //             effects: true, // looks for data-speed and data-lag attributes on elements
    //             // speed: 2,
    //             // smoothTouch: 0.1,
    //             smoothTouch: 0.1,
    //         });
    //     }
    // }, [location]);

    const wrapperRef = useRef();
    const contentRef = useRef();
    let smoother;

    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
            smoother = ScrollSmoother.create({
                wrapper: wrapperRef.current,
                content: contentRef.current,
                smooth: 2,
                effects: true,
                smoothTouch: 0.1
            });
        }
        ScrollTrigger.refresh();
        return () => {
            if (smoother) smoother.kill();
        };
    }, [location]);


    useEffect(() => {
        window.scroll(0, 0);
        if (window.location.hash) {
            gsap.to(window, { duration: 1, scrollTo: window.location.hash });
        }
    });

    const [deviceWidth, setDeviceWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    useEffect(() => {
        function handleWidth() {
            setDeviceWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWidth);
        return () => {
            window.removeEventListener('resize', handleWidth);
        };
    }, []);




    useGSAP(() => {
        document.querySelector(".scroll")?.addEventListener("click", (e) => {
            e.preventDefault();
            smoother.scrollTo("#overview", true, "top -100px", { duration: 1 });
        });
    }, []);

    const [bgColor, setBGColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#ffffff');

    useEffect(() => {
        if (
            location === '/about-us' ||
            location === '/business-verticals' ||
            location === '/csr' ||
            location === '/news-events' ||
            location.startsWith('/news-events') ||
            location === '/career' ||
            location === '/contact'
        ) {
            setBGColor('#ffffff');
            setTextColor('#000000');
        } else {
            setBGColor('transparent');
            setTextColor('#ffffff');
        }
    }, [location]);

    Parallax();
    TextAnimation();
    ImageReveal();




    return (
        <html lang="en">
        <head>
            <link rel="icon" type="image/png" href="/images/static/fav-icon.png" />
            <meta content="#000000" name="theme-color" />
            <link href="https://2309-sag.vercel.app/" rel="canonical" hrefLang="en"/>
            <title>SA Group</title>
        </head>
        <body>
        <StyledComponentsRegistry>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    async: false,
                    defer: false,
                    appendTo: 'head',
                }}
            >
                {
                    location === '/' &&
                    <Transition/>
                }
                {/*<PageTransition />*/}
                {/*<div id="main-root">*/}
                {/*    */}
                {/*</div>*/}
                <GlobalStyle />
                <ToastContainer />
                <Menu bgColor={bgColor} textColor={textColor} />
                <div ref={wrapperRef} id="smooth-wrapper">
                    <div ref={contentRef} id="smooth-content">
                        {children}
                        <Footer/>
                    </div>
                </div>
            </GoogleReCaptchaProvider>
        </StyledComponentsRegistry>
        </body>
        </html>
    );
}
